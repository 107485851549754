<template>
  <!-- Frequency Cap Change Panel -->
  <section class="u-width-100">
    <section
      v-if="loader"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="loader"
        :color="'#007cf6'"
      />
    </section>

    <section
      v-show="!loader"
      class="freqCapChangePanel u-bg-color-grey-white"
    >
      <header
        class="title u-font-size-5 u-font-weight-600 u-spacing-pv-l u-spacing-ph-l u-text-case-upper"
      >
        {{ title }} ({{ panelSelectionCount }})
      </header>
      <section class="content u-display-flex u-spacing-pb-l u-spacing-ph-l">
        <div class="radio-holder">
          <div
            class="u-font-size-5 u-color-grey-light u-width-120px"
            @change="changeRadio"
          >
            <div class="u-spacing-mb-m">
              <rb-radio
                v-model="capType"
                native-value="0"
              >
                Set new {{ capDisplayText[actionPanelType] }}
              </rb-radio>
            </div>
            <div class="u-spacing-mb-m">
              <rb-radio
                v-model="capType"
                native-value="1"
              >
                Increase {{ capDisplayText[actionPanelType] }}
              </rb-radio>
            </div>
            <div class="u-spacing-mb-xs">
              <rb-radio
                v-model="capType"
                native-value="2"
              >
                Decrease {{ capDisplayText[actionPanelType] }}
              </rb-radio>
            </div>
          </div>
        </div>
        <div class="u-spacing-pl-xxxl">
          <div
            class="u-display-flex u-flex-align-items-center u-color-grey-base u-flex-wrap-yes"
          >
            <div
              class="u-display-flex u-flex-0 u-width-140px u-flex-direction-column u-position-relative"
            >
              <label
                for="#count"
                class="u-font-weight-600 u-font-size-5"
              >
                Count:
              </label>
              <input
                id="count"
                ref="new_cap"
                v-model="countValue"
                type="number"
                name="countValue"
                class="rb-input count u-display-flex"
                :min="validationObj[actionPanelType].absolute.min"
                step="1"
                :max="validationObj[actionPanelType].absolute.max"
                autofocus
                @input="checkForMaxCap($event)"
              />
              <div
                class="u-display-flex u-flex-align-items-center u-spacing-pt-xs u-color-grey-lighter"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--small"
                  :icon="'info-circle-fill'"
                />
                <span class="u-font-size-7 u-spacing-pl-xxs"
                  >Change by absolute value</span
                >
              </div>
              <div class="error-container">
                <!-- <rb-icon class="u-flex-0 rb-icon--small u-color-grey-lighter" :icon="'info-circle-fill'"></rb-icon> -->
                <span
                  v-if="showError['countValue']"
                  class="u-flex-0 u-font-size-7 u-color-red-base"
                  >{{ errorMessage['countValue'] }}</span
                >
              </div>
            </div>
            <span
              v-if="capType === '0'"
              class="u-font-size-5 u-spacing-ph-s"
              >Times per</span
            >
            <div
              v-if="capType === '0'"
              class="u-display-flex u-flex-0 u-width-140px u-flex-direction-column u-position-relative"
            >
              <label
                for="#timeUnitCount"
                class="u-font-weight-600 u-font-size-5"
              >
                Time Unit Count:
              </label>
              <input
                id="timeUnitCount"
                v-model="timeUnitCount"
                name="timeUnitCount"
                type="number"
                class="rb-input count u-display-flex"
                :min="validationObj[actionPanelType].absolute.min"
                step="1"
                :max="validationObj[actionPanelType].absolute.max"
                autofocus
                @input="checkForMaxCap($event)"
              />
              <div
                class="u-display-flex u-flex-align-items-center u-spacing-pt-xs u-color-grey-lighter"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--small"
                  :icon="'info-circle-fill'"
                />
                <span class="u-font-size-7 u-spacing-pl-xxs"
                  >Change by absolute value</span
                >
              </div>
              <div class="error-container">
                <!-- <rb-icon class="u-flex-0 rb-icon--small u-color-grey-lighter" :icon="'info-circle-fill'"></rb-icon> -->
                <span
                  v-if="showError['timeUnitCount']"
                  class="u-flex-0 u-font-size-7 u-color-red-base"
                  >{{ errorMessage['timeUnitCount'] }}</span
                >
              </div>
            </div>
            <div
              v-if="capType === '0'"
              class="select-box u-display-flex u-flex-0 u-width-140px u-flex-direction-column u-flex-align-self-flex-start u-spacing-pl-l"
            >
              <rb-select
                class="valueDropdown count"
                :send-details="true"
                :on-close="optionSelected"
                :options="dropdownOptions"
              >
                <div
                  slot="trigger"
                  class="option u-display-flex u-flex-align-items-center u-flex-justify-content-space-around u-cursor-pointer"
                >
                  <div
                    class="u-display-flex u-flex-direction-column u-spacing-p-s"
                  >
                    <span class="u-font-size-5">{{
                      selectedOption.title
                    }}</span>
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div class="u-display-flex u-flex-align-items-center">
                    {{ option.title }}
                  </div>
                </template>
              </rb-select>
            </div>
          </div>
        </div>
      </section>
      <footer class="freqCapChangePanelFooter">
        <div
          class="u-display-flex u-flex-direction-column u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-color-grey-xxx-light"
        >
          <div
            v-if="confirmCheckRequired"
            class="u-display-flex u-spacing-mb-m"
          >
            <rb-checkbox
              key="confirmHighValue"
              v-model="validationConfirmed"
              native-value="false"
              class="u-width-50"
            >
              {{ confirmMessage }}
            </rb-checkbox>
          </div>
          <div class="u-display-flex">
            <rb-button
              :click-fn="takeAction"
              :text="'Apply'"
              :size="'s'"
              :type="'filled'"
              class="u-spacing-mr-s"
              :disabled="applyButtonValidity"
              :class="{
                disabled: applyButtonValidity
              }"
            />
            <rb-button
              :click-fn="takeAction"
              :text="'Cancel'"
              :size="'s'"
              :type="'hollow'"
              class="u-spacing-mr-s"
            />
          </div>
        </div>
      </footer>
    </section>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';

const getCountValue = function (params, key, oldVal) {
  oldVal = parseFloat(oldVal) || 0;
  const delta = parseFloat(params.value[key].value);
  if (params.value.type === 'increaseCap') {
    return parseFloat((oldVal + delta).toFixed(2));
  } else {
    return parseFloat((oldVal - delta).toFixed(2));
  }
};

export default {
  name: 'FreqCapChangePanel',
  components: {
    loader
  },
  props: {
    primaryKey: {
      type: String,
      default: null
    },
    widget: {
      type: String,
      default: null
    },
    apiVersion: {
      type: String,
      default: 'worklog'
    },
    retailer: {
      default: 'amazon',
      type: String
    },
    selectedValues: {
      type: Array,
      default: function () {
        return [];
      }
    },
    title: {
      type: String,
      default: ''
    },
    confirmCheckRequired: {
      type: Boolean,
      default: false
    },
    closePanelMethod: {
      type: Function,
      default: function () {}
    },
    afterAPIActionToCall: {
      type: Function,
      default: function () {}
    },
    actionPanelType: {
      type: String,
      default: 'keywords'
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      capDisplayText: {
        orders: 'cap',
        lineItems: 'cap'
      },
      countValue: '',
      timeUnitCount: '',
      capType: '0',
      dropdownOptions: [
        {
          title: 'Days',
          value: 'DAYS'
        },
        {
          title: 'Hours',
          value: 'HOURS'
        }
      ],
      selectedOption: null,
      formValid: false,
      errorMessage: {
        countValue: '',
        timeUnitCount: ''
      },
      showError: {
        countValue: false,
        timeUnitCount: false
      },
      actionMap: {
        amazon: {
          orders: {
            actionName: 'dspOrderFrequencyCapChange',
            countValue: {
              currentValKey: 'dsp_orders_frequency_cap_max_impressions',
              previousValKey: 'previousFrequencyCapMaxImpressions',
              newValKey: 'frequencyCapMaxImpressions'
            },
            timeUnit: {
              currentValKey: 'dsp_orders_frequency_cap_time_unit',
              previousValKey: 'previousFrequencyCapTimeUnit',
              newValKey: 'frequencyCapTimeUnit'
            },
            timeUnitCount: {
              currentValKey: 'dsp_orders_frequency_cap_time_unit_count',
              previousValKey: 'previousFrequencyCapTimeUnitCount',
              newValKey: 'frequencyCapTimeUnitCount'
            }
          },
          lineItems: {
            actionName: 'dspLineItemFrequencyCapChange',
            countValue: {
              currentValKey: 'dsp_line_items_frequency_cap_max_impressions',
              previousValKey: 'previousFrequencyCapMaxImpressions',
              newValKey: 'frequencyCapMaxImpressions'
            },
            timeUnit: {
              currentValKey: 'dsp_line_items_frequency_cap_time_unit',
              previousValKey: 'previousFrequencyCapTimeUnit',
              newValKey: 'frequencyCapTimeUnit'
            },
            timeUnitCount: {
              currentValKey: 'dsp_line_items_frequency_cap_time_unit_count',
              previousValKey: 'previousFrequencyCapTimeUnitCount',
              newValKey: 'frequencyCapTimeUnitCount'
            }
          }
        }
      },
      validationObj: {
        amazon: {
          orders: {
            absolute: {
              max: 100,
              min: 1
            }
          },
          lineItems: {
            absolute: {
              max: 100,
              min: 1
            }
          }
        }
      },
      loader: false,
      capActions: {
        0: 'newCap',
        1: 'increaseCap',
        2: 'decreaseCap'
      },
      validationConfirmed: false
    };
  },
  computed: {
    panelSelectionCount() {
      const selectedValuesLength = this.selectedValues.length;
      let panelName = 'Campaign';
      if (this.actionPanelType === 'orders') {
        panelName = selectedValuesLength > 1 ? 'orders' : 'order';
      } else if (this.actionPanelType === 'lineItems') {
        panelName = selectedValuesLength > 1 ? 'line item' : 'line items';
      }
      return selectedValuesLength + ' ' + panelName + ' selected';
    },
    applyButtonValidity() {
      let flag = false;
      if (this.confirmCheckRequired === false) {
        if (this.capType === '0') {
          flag =
            this.countValue.length === 0 ||
            this.timeUnitCount.length === 0 ||
            this.formValid === false ||
            this.selectedValues.length === 0;
        } else {
          flag = this.countValue.length === 0 || this.formValid === false;
        }
      }
      return flag;
    },
    confirmMessage() {
      const message = {
        orders: 'order frequency cap',
        lineItems: 'line item frequency cap'
      };
      return `I understand and accept the big changes made to some of the ${
        message[this.actionPanelType]
      }.`;
    }
  },
  watch: {
    capType: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.validationConfirmed = false;
      }
    }
  },
  created() {
    this.selectedOption = this.dropdownOptions[0];
    this.actionMap = { ...this.actionMap[this.retailer] };
    this.validationObj = { ...this.validationObj[this.retailer] };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.new_cap.focus();
    });
  },
  methods: {
    placeholderVal() {
      return 'Enter a value';
    },

    takeAction(type) {
      const valObj = {
        type: this.capActions[this.capType],
        countValue: { value: this.countValue },
        timeUnitCount: { value: this.timeUnitCount },
        timeUnit: { value: this.selectedOption.value }
      };
      const objToPass = {
        actionType: type.textContent.trim().toLowerCase(),
        value: valObj
      };
      if (objToPass.actionType === 'apply') {
        this.calCapActionApi(objToPass);
      } else {
        this.$emit('closePanelMethod');
      }
    },
    changeRadio() {
      this.countValue = '';
      this.timeUnitCount = '';
      this.selectedOption = this.dropdownOptions[0];
      this.$nextTick(() => {
        this.$refs.new_cap.focus();
      });
    },
    optionSelected(context, selectedOption) {
      this.selectedOption = selectedOption[0];
    },
    isNullOrUndefined(val) {
      if (val === null || val === undefined) {
        return true;
      }
      return false;
    },
    checkForMaxCap(event, actionObject, key) {
      const inputVal = event.target.value;
      const name = event.target.name;
      if (!inputVal) {
        return;
      }
      const value = Number(inputVal).toFixed(2);
      this.showError[name] = false;

      let valObj = {
        type: this.capActions[this.capType],
        countValue: { value: this.countValue },
        timeUnitCount: { value: this.timeUnitCount },
        timeUnit: { value: this.selectedOption.value }
      };
      this.getPayload({ value: valObj });

      this.formValid = false;
      const maxCap = this.validationObj[this.actionPanelType].absolute.max;
      const minCap = this.validationObj[this.actionPanelType].absolute.min;
      if (!this.isNullOrUndefined(maxCap) && !this.isNullOrUndefined(minCap)) {
        if (value > maxCap || value < minCap) {
          this.errorMessage[name] =
            'Absolute value should be between ' + minCap + ' to ' + maxCap;
          this.showError[name] = true;
          return;
        }
      } else if (!this.isNullOrUndefined(maxCap)) {
        if (value > maxCap) {
          this.errorMessage[name] =
            'Absolute value should be less than ' + maxCap;
          this.showError[name] = true;
          return;
        }
      } else if (!this.isNullOrUndefined(minCap)) {
        if (value < minCap) {
          this.errorMessage[name] =
            'Absolute value should be greater than ' + minCap;
          this.showError[name] = true;
          return;
        }
      }
      this.errorMessage[name] = '';
      this.formValid = Object.values(this.errorMessage).every((val) => !val);
      valObj = {
        type: this.capActions[this.capType],
        countValue: { value: this.countValue },
        timeUnitCount: { value: this.timeUnitCount },
        timeUnit: { value: this.selectedOption.value }
      };
      this.getPayload({ value: valObj });
    },

    // initial payload object as per the panel type
    getRetailerBasedInitialObject(data) {
      const obj = {
        actionPayload: {
          profileId: data.profile_id || 0,
          clientName: window.clientName,
          actionType: this.actionMap[this.actionPanelType].actionName
        },
        viewPayload: {
          newStatus: status,
          profileId: data.profile_id || 0,
          clientName: window.clientName
        },
        actionType: this.actionMap[this.actionPanelType].actionName,
        actionSource: {
          pageUrl: window.location.href
        }
      };
      this.retailerSpecificChanges(obj, data);
      return obj;
    },
    retailerSpecificChanges(obj, data) {
      if (this.retailer === 'amazon') {
        if (this.actionPanelType === 'orders') {
          obj.actionPayload.orderId = data[this.primaryKey];
          obj.viewPayload.orderId = data[this.primaryKey];
          obj.actionPayload.clientName = window.clientName;
          obj.viewPayload.clientName = window.clientName;
          obj.actionPayload.profileId = data.dsp_orders_profile_id;
          obj.viewPayload.profileId = data.dsp_orders_profile_id;
        }
        if (this.actionPanelType === 'lineItems') {
          obj.actionPayload.lineItemId = data[this.primaryKey];
          obj.viewPayload.lineItemId = data[this.primaryKey];
          obj.actionPayload.clientName = window.clientName;
          obj.viewPayload.clientName = window.clientName;
          obj.actionPayload.profileId = data.dsp_line_items_profile_id;
          obj.viewPayload.profileId = data.dsp_line_items_profile_id;
        }
      }
    },
    getInitialPayloadObject(data) {
      let obj = this.getRetailerBasedInitialObject(data);
      for (const key of ['timeUnit', 'timeUnitCount', 'countValue']) {
        const newKey = this.actionMap[this.actionPanelType][key].newValKey;
        const prevKey =
          this.actionMap[this.actionPanelType][key].previousValKey;
        const rowDataKey =
          this.actionMap[this.actionPanelType][key].currentValKey;
        obj.actionPayload[newKey] = '';
        obj.viewPayload[prevKey] = data[rowDataKey];
        obj.viewPayload[newKey] = '';
      }
      obj = this.setAdditionalActionlogApiMetric(obj, data);
      return obj;
    },

    setAdditionalActionlogApiMetric(obj, data) {
      if (this.apiVersion === 'actionlog') {
        const dataKey =
          this.actionMap[this.actionPanelType].countValue.currentValKey;
        obj.primaryKey = data[this.primaryKey];
        obj.widget = this.widget;
        obj.previousEntityValue = data[dataKey];
      }
      return obj;
    },

    // updates the payload with updated values as per required calculations for freq change

    processPayloadForfreqCapChange(params, payloadObjRef, rowData) {
      for (const key of ['timeUnit', 'timeUnitCount', 'countValue']) {
        const newKey = this.actionMap[this.actionPanelType][key].newValKey;
        const prevKey =
          this.actionMap[this.actionPanelType][key].previousValKey;
        const rowDataKey =
          this.actionMap[this.actionPanelType][key].currentValKey;
        if (
          params.value.type === 'increaseCap' ||
          params.value.type === 'decreaseCap'
        ) {
          if (key === 'countValue') {
            const mathsFunc =
              params.value.type === 'increaseCap' ? 'min' : 'max';
            const limit =
              params.value.type === 'increaseCap'
                ? this.validationObj[this.actionPanelType].absolute.max
                : this.validationObj[this.actionPanelType].absolute.min;
            let newVal = null;
            if (!this.isNullOrUndefined(limit)) {
              newVal = Math[mathsFunc](
                getCountValue(params, key, rowData[rowDataKey]),
                limit
              );
            } else {
              newVal = getCountValue(params, key, rowData[rowDataKey]);
            }

            payloadObjRef.actionPayload[newKey] = newVal;
            payloadObjRef.viewPayload[newKey] = newVal;
            if (this.apiVersion === 'actionlog') {
              payloadObjRef.newEntityValue = newVal;
            }
          } else {
            // in case of increase or decrease cap set frequencyTimeUnit and frequencyTimeUnitCount to the current value itself
            payloadObjRef.actionPayload[newKey] = rowData[rowDataKey];
            payloadObjRef.viewPayload[newKey] = rowData[rowDataKey];
          }
        } else {
          const value =
            key !== 'timeUnit'
              ? parseFloat(params.value[key].value).toFixed(2)
              : params.value[key].value; // as time unit is one of two value 'DAYS' or 'HOURS'
          payloadObjRef.actionPayload[newKey] = value;
          payloadObjRef.viewPayload[prevKey] = rowData[rowDataKey];
          payloadObjRef.viewPayload[newKey] = value;
          if (this.apiVersion === 'actionlog' && key === 'countValue') {
            // as payload takes only count value as newEntityValue
            payloadObjRef.newEntityValue = value;
          }
        }
      }
    },

    // function that creates the payload which is used in API
    getPayload(params) {
      const paramsToReturn = [];
      for (let i = 0; i < this.selectedValues.length; i++) {
        const data = this.selectedValues[i].data;
        const obj = this.getInitialPayloadObject(data);
        this.processPayloadForfreqCapChange(params, obj, data);
        paramsToReturn.push(obj);
      }
      return paramsToReturn;
    },

    // API call
    calCapActionApi(params) {
      let name = '';
      if (this.actionPanelType === 'orders') {
        name = 'order(s)';
      } else if (this.actionPanelType === 'lineItems') {
        name = 'line item(s)';
      }
      const that = this;
      const payload = this.getPayload(params);
      const othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted for the selected ' + name,
        errorMessage: 'Something went wrong.',
        successFn: () => {},
        errorFn: () => {
          that?.parentRef.$emit('openSnackbar', 'Something went wrong');
        },
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(
        this,
        params,
        payload,
        othersObj,
        this.apiVersion
      );
    }
  }
};
</script>

<style lang="css" scoped>
.loaderContainer {
  position: relative;
  height: 230px;
}
.valueDropdown {
  width: 100px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
  line-height: 1.35;
}
.freqCapChangePanel .radio-holder {
  border-right: 1px solid #e9eaeb;
  padding-right: 6.4rem;
}

.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 90px;
  width: 100%;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.count {
  height: 36px;
  max-width: 100%;
  padding-left: 8px;
}

.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}

.u-width-140px {
  width: 140px !important;
}

.select-box {
  margin-top: 1.4rem;
  height: 3.6rem;
}

.error-container {
  position: absolute;
  bottom: -3rem;
}
</style>
